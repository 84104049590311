<template>
  <div>
    <div class="header">
      <div>
        <el-page-header @back="goBack" :content="title"> </el-page-header>
      </div>
    </div>
    <div class="form-warp">
      <el-form
        label-position="top"
        width="900px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="80px"
      >
        <Divider :title="'基本信息'"></Divider>
        <el-form-item label="职位名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入职位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="招聘人数" prop="number">
          <el-input-number
            v-model="ruleForm.number"
            :min="0"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="工作地" prop="location">
          <District v-model="ruleForm.location" :level="level"></District>
        </el-form-item>
        <el-form-item label="详细地址" prop="locationDetail">
          <el-input
            v-model="ruleForm.locationDetail"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="最低薪资(元)" prop="salaryMinAmount">
          <el-input-number
            v-model="ruleForm.salaryMinAmount"
            :min="0"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="最高薪资(元)" prop="salaryMaxAmount">
          <el-input-number
            v-model="ruleForm.salaryMaxAmount"
            :min="0"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>

        <el-form-item label="薪资单位" prop="salaryUnit">
          <el-select
            v-model="ruleForm.salaryUnit"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in salaryUnitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="福利" prop="welfare">
          <el-select
            v-model="ruleForm.welfare"
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in welfareOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="岗位职责" prop="duty" style="width: 100%">
          <el-input
            v-model="ruleForm.duty"
            type="textarea"
            rows="5"
            placeholder="请输入岗位职责"
          ></el-input>
        </el-form-item>
        <Divider :title="'岗位要求'"></Divider>
        <el-form-item label="最低学历" prop="educational">
          <el-select
            v-model="ruleForm.educational"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in educationalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="major">
          <el-input v-model="ruleForm.major"></el-input>
        </el-form-item>

        <el-form-item label="最少工作年限(年)" prop="minWorkingYears">
          <el-input-number
            v-model="ruleForm.minWorkingYears"
            :min="0"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="应聘者年龄限制(最大可接受年龄)" prop="maxage">
          <el-input-number
            v-model="ruleForm.maxage"
            :min="18"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="其他要求" prop="requirement" style="width: 100%">
          <el-input
            v-model="ruleForm.requirement"
            type="textarea"
            rows="5"
            placeholder="请输入岗位要求"
          ></el-input>
        </el-form-item>

        <Divider :title="'雇主信息'"></Divider>
        <el-form-item label="企业名称" prop="enterpriseName">
          <el-input
            v-model="ruleForm.enterpriseName"
            placeholder="请输入招聘企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input
            v-model="ruleForm.contacts"
            placeholder="请输入招聘企业联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="tel">
          <el-input
            v-model="ruleForm.tel"
            placeholder="请输入招聘企业联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联的合同" prop="linkContractCode">
          <el-select
            v-model="ruleForm.linkContractCode"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in contractOptions"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <Divider :title="'扩散配置'"></Divider>
        <el-form-item label="分享海报图片" prop="postImage">
          <Upload v-model="ruleForm.postImage" path="/recruit.upload.json">
            上传
          </Upload>
        </el-form-item>

        <!-- <el-form-item label="是否置顶" prop="isTop">
        <el-switch v-model="ruleForm.isTop"> </el-switch>
      </el-form-item>

      <el-form-item label="置顶优先级" prop="topIndex">
        <el-input-number
          v-model="ruleForm.topIndex"
          :min="0"
          label="描述文字"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="是否在banner中显示" prop="isBanner">
        <el-switch v-model="ruleForm.isBanner"> </el-switch>
      </el-form-item>

      <el-form-item label="banner图片" prop="bannerImage">
        <Upload v-model="ruleForm.bannerImage" path="/recruit.upload.json">
          上传
        </Upload>
      </el-form-item> -->

        <!-- <el-form-item label="备注" prop="remark">
        <el-input v-model="ruleForm.remark"></el-input>
      </el-form-item> -->

        <el-form-item label="有效期" prop="validityTime">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="ruleForm.validityTime"
            style="width: 100%"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>

        <!-- <el-form-item label="状态 1草稿 2已发布 3已关闭" prop="state">
        <el-input v-model="ruleForm.state"></el-input>
      </el-form-item> -->

        <el-form-item label="悬赏金额(元/人)" prop="deposit">
          <el-input-number
            v-model="ruleForm.deposit"
            :min="0"
            label="描述文字"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="悬赏分配方案" prop="returnPlan">
          <el-select
            v-model="ruleForm.returnPlan"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in rcruitReturnPlanOptions"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '/src/api/base';
import * as _ from 'lodash';
//新增接口
export const RecruitAdd = api('/recruit')('recruit.add.json');
//查询列表接口
export const RecruitList = api('/recruit')('recruit.list.json');
//查询详情接口
export const RecruitDetail = api('/recruit')('recruit.describe.json');
//编辑接口
export const RecruitEdit = api('/recruit')('recruit.edit.json');
//删除接口
export const RecruitDel = api('/recruit')('recruit.del.json');
//查询内容列表接口
export const ContentList = api('/content')('content.list.json');
//查询内容列表接口
export const ContractList = api('/contract')('contract.list.json');
//查询内容列表接口
export const getRecruitReturnPlanList = api('/contract')(
  'recruitReturnPlan.list.json'
);
export default {
  components: {
    District: () => import('/src/components/district'),
    Upload: () => import('/src/components/upload.vue'),
    Divider: () => import('/src/components/divider.vue')
  },
  data() {
    // var validatePhone = (rule, value, callback) => {
    //   const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    //   if (value === "") {
    //     callback(new Error("请输入登录名"));
    //   } else if (!phoneReg.test(value)) {
    //     callback(new Error("请输入正确手机号"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      title: '新建招聘',
      code: '',
      educationalOptions: [
        {
          value: '不限',
          lable: '不限'
        },
        {
          value: '大专',
          lable: '大专'
        },
        {
          value: '本科',
          lable: '本科'
        },
        {
          value: '研究生',
          lable: '研究生'
        }
      ],
      salaryUnitOptions: [
        {
          label: '时薪',
          value: '时薪'
        },
        {
          label: '日薪',
          value: '日薪'
        },
        {
          label: '月薪',
          value: '月薪'
        },
        {
          label: '年薪',
          value: '年薪'
        }
      ],
      welfareOptions: [
        {
          label: '五险一金',
          value: '五险一金'
        },
        {
          label: '带薪年假',
          value: '带薪年假'
        }
      ],
      contentOptions: [],
      contractOptions: [],
      rcruitReturnPlanOptions: [],
      ruleForm: {
        // code: "",
        name: '',
        number: '1',
        location: '',
        duty: '',
        requirement: '',
        educational: '不限',
        major: '不限',
        locationDetail: '',
        salaryMinAmount: '5000',
        salaryMaxAmount: '10000',
        salaryUnit: '月薪',
        enterpriseName: '',
        contacts: '',
        tel: '',
        welfare: '',
        minWorkingYears: '0',
        postImage: '',
        content: '',
        createTime: '',
        remark: '',
        linkContractCode: '',
        linkContractName: '',
        validityTime: '',
        // state: "",
        deposit: '',
        maxage: 60,
        returnPlan: ''
      },
      rules: {
        name: [{ required: true, message: '请填写职位名称', trigger: 'blur' }],
        number: [],
        location: [
          { required: true, message: '请选择所在地区', trigger: 'blur' }
        ],
        duty: [],
        requirement: [],
        educational: [],
        major: [],
        locationDetail: [],
        salaryMinAmount: [],
        salaryMaxAmount: [],
        salaryUnit: [],
        enterpriseName: [
          { required: true, message: '请填写招聘企业名称', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' }
        ],
        tel: [
          {
            required: true,
            message: '请填写联系人电话',
            trigger: 'blur'
          }
        ],
        welfare: [],
        minWorkingYears: [],
        postImage: [],
        content: [],
        createTime: [
          { required: true, message: '请选择创建时间', trigger: 'blur' }
        ],
        isTop: [],
        topIndex: [],
        isBanner: [],
        bannerImage: [],
        remark: [],
        linkContractCode: [],
        linkContractName: [],
        validityTime: [
          { required: true, message: '请选择有效期', trigger: 'blur' }
        ],
        state: [],
        deposit: [
          { required: true, message: '请填写保证金金额', trigger: 'blur' }
        ]
      },
      level: 'city'
    };
  },
  created() {
    if (this.$route.query.code) {
      this.title = '编辑招聘';
      this.code = this.$route.query.code;
      this.getDetail();
    }
    this.getContentList();
    this.getContractList();
    this.getRecruitReturnPlan();
  },
  methods: {
    async getRecruitReturnPlan() {
      const result = await getRecruitReturnPlanList({
        pageNumber: 1,
        pageSize: 1000
      });
      this.rcruitReturnPlanOptions = result.list;
    },
    async getDetail() {
      const res = await RecruitDetail({ code: this.code });
      Object.keys(this.ruleForm).forEach(it => {
        this.ruleForm[it] = res[it];
      });
      this.ruleForm.welfare = this.ruleForm.welfare.split(',');
      console.log(this.ruleForm.location);
    },
    async getContentList() {
      const res = await ContentList({
        // keywords: "",
        pageNumber: 1,
        pageSize: 1000,
        type: '招聘内容'
      });
      this.contentOptions = res.list;
    },
    async getContractList() {
      const res = await ContractList({
        pageNumber: 1,
        pageSize: 1000
      });
      this.contractOptions = res.list;
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          Object.keys(this.ruleForm).forEach(key => {
            const it = this.ruleForm[key];
            if (_.isArray(it)) {
              this.ruleForm[key] = it.join(',');
            }
          });
          if (this.ruleForm.linkContractCode) {
            this.ruleForm.linkContractName = this.contractOptions.find(
              it => it.code === this.ruleForm.linkContractCode
            )?.name;
          }
          this.ruleForm.deposit = this.ruleForm.deposit * 1;
          if (this.title === '新建招聘') {
            RecruitAdd(this.ruleForm).then(() => {
              this.$refs[formName].resetFields();
              this.$router.push({
                path: '/system/recruit/list'
              });
            });
            this.$message.success('新建成功！');
          }
          if (this.title === '编辑招聘') {
            RecruitEdit({
              code: this.code,
              ...this.ruleForm
            }).then(() => {
              this.$refs[formName].resetFields();
              this.$router.push({
                path: '/system/recruit/list'
              });
            });
            this.$message.success('编辑成功！');
          }
        } else {
          return false;
        }
      });
    },
    goBack() {
      window.history.go(-1);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-warp {
  width: 800px;
  margin: 0px auto;
  padding: 20px;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
.header {
  border-bottom: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
